import React from "react";
import { useUsuarioLogado, Secured } from "../../component";
import { Permissions } from "../../Constantes/permissions.constants";
import { useHistory } from "react-router-dom";
import RegrasAplicaveis from "./RegrasAplicaveis.js";
import ResumoContributivo from "./ResumoContributivo.js";

import {
  converterDataParaAnoMesDia,
  convertObjectNewDateToStr,
  imprimirRelatorio,
  transformarDataStringComBarra,
  transformarDataStringComTraco,
} from "../../helper/util";
import { Card, Col, Form, Row, Button, Input, Modal, Select } from "antd";
import { PatternFormat } from "react-number-format";
import services from "../SimulacaoAposentadoria/services";

import AddButtonCard from "../../component/AddButtonCard";
import {
  PrinterOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import InputDate from "../../component/InputDate";

const SimulacaoAposentadoria = (props) => {
  const {
    atendente = false,
    isSeguradoSelecionado = false,
    idVinculoFuncional,
    funcional,
    referencia,
    loadingg = false,
  } = props;

  let history = useHistory();
  const [dataFuncional, setDataFuncional] = React.useState(
    funcional ? funcional : undefined
  );
  const [loading, setLoading] = React.useState(loadingg);
  const [loadingRegrasApliveis, setLoadingRegrasAplicaveis] =
    React.useState(loading);
  const [loadingPeriodosContributivos, setLoadingPeriodosContributivos] =
    React.useState(false);
  const [periodosContributivos, setPeriodosContributivos] = React.useState([]);
  const [regrasAplicaveis, setRegrasAplicaveis] = React.useState([]);
  const [form] = Form.useForm();
  const [idVinculo, setIdVinculo] = React.useState(idVinculoFuncional);
  const [refreshVinculo, setRefreshVinculo] = React.useState(0);
  const [dataReferenciaModificada, setDataReferenciaModificada] =
    React.useState();
  const [openModalFinalSimulator, setOpenModalFinalSimulator] =
    React.useState(false);
  const [permiteVerTempoContribuicao, setPermiteVerTempoContribuicao] =
    React.useState(false);
  const [isExibe, setIsExibe] = React.useState(false);
  const [sumula, setSumula] = React.useState({});
  const [simulacaoBeneficioEspecial, setSimulacaoBeneficioEspecial] =
    React.useState({});
  const usuarioLogado = useUsuarioLogado();
  const [loadingSimulacaoFinal, setLoadingSimulacaoFinal] =
    React.useState(false);
  const [loadingSimulacaoCompleta, setLoadingSimulacaoCompleta] =
    React.useState(false);
  const [loadingCalculodaMedia, setLoadingCalculodaMedia] =
    React.useState(false);
  const [
    loadingRelatorioTempoContribuicao,
    setLoadingRelatorioTempoContribuicao,
  ] = React.useState(false);
  const [beneficioEspecial, setBeneficioEspecial] = React.useState(false);
  const [descricaoPeriodosAdicionais, setDescricaoPeriodosAdicionais] =
    React.useState({});
  const [resumoTempoRGPS, setResumoTempoRGPS] = React.useState(
    dataFuncional?.vinculos[0].resumoTempoRGPS
  );
  const [resumoTempoRPPS, setResumoTempoRPPS] = React.useState(
    dataFuncional?.vinculos[0].resumoTempoRPPS
  );

  const tiposDeAverbacoes = [
    { nome: "Serviço Público", value: 1 },
    { nome: "Serviço Privado", value: 2 },
  ];

  const regras = [
    {
      nome: "Incapacidade Permanente - Demais casos",
      value: "Incapacidade Permanente - Demais casos",
    },
    {
      nome: "Incapacidade Permanente - Doença do Trabalho",
      value: "Incapacidade Permanente - Doença do Trabalho",
    },
  ];

  React.useEffect(() => {
    if (isSeguradoSelecionado && referencia) {
      setLoadingRegrasAplicaveis((prev) => true);
      setLoadingPeriodosContributivos((prev) => true);
      services
        .getAverbacoes(idVinculo)
        .then(async (res) => {
          res.data.map((periodo) => {
            periodo.fim = periodo.fim === "Atual" ? "Atual" : periodo.fim;
          });

          if (res) {
            setPeriodosContributivos((prev) => res.data);

            form.setFieldsValue({ periodosContributivos: res.data });

            await services
              .obterRegrasAplicaveis(idVinculo, referencia)
              .then((response) => {
                setDataReferenciaModificada(
                  (prev) => response.dataReferenciaModificada
                );
                setRegrasAplicaveis((prev) => response.regrasAplicaveis);
                setPermiteVerTempoContribuicao(
                  (prev) => response.permiteVerTempoContribuicao
                );
                setDescricaoPeriodosAdicionais(
                  (prev) => response.descricaoPeriodosAdicionais
                );

                setSumula((prev) => response.sumula);
                setSimulacaoBeneficioEspecial(
                  (prev) => response.simulacaoBeneficioEspecial
                );
                setSimulacaoBeneficioEspecial(
                  (prev) => response.simulacaoBeneficioEspecial
                );
                setIsExibe((prev) => true);
                setSimulacaoBeneficioEspecial(
                  (prev) => response.simulacaoBeneficioEspecial
                );
                setLoadingRegrasAplicaveis((prev) => false);
                setBeneficioEspecial(
                  (prev) => response.permiteAdesaoBeneficioEspecial
                );
                setResumoTempoRGPS(
                  (prev) => response.sumula.resumoContribuicaoGeral
                );
                setResumoTempoRPPS(
                  (prev) => response.sumula.resumoServicoPulico
                );
              })
              .catch(() => {
                setLoadingRegrasAplicaveis((prev) => false);
                setBeneficioEspecial((prev) => false);
              });
          }
          setLoading((prev) => false);
          setLoadingPeriodosContributivos((prev) => false);
        })
        .catch(() => {
          setIsExibe((prev) => false);
          setLoading((prev) => false);
          setLoadingPeriodosContributivos((prev) => false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (!atendente) {
      const cpfFuncional = usuarioLogado.userName;
      const date = convertObjectNewDateToStr();
      const currentDateRef = transformarDataStringComTraco(date);

      if (cpfFuncional) {
        setLoading((prev) => true);
        setLoadingRegrasAplicaveis((prev) => true);
        services.getFuncionais(cpfFuncional).then(async (resp) => {
          setDataFuncional((prev) => resp.data);
          setIdVinculo((prev) => resp.data.vinculos[0].id);

          if (resp.data.vinculos[0].id) {
            setLoadingPeriodosContributivos((prev) => true);
            await services
              .getAverbacoes(resp.data.vinculos[0].id)
              .then(async (res) => {
                res.data.map((periodo) => {
                  periodo.fim = periodo.fim === "Atual" ? "Atual" : periodo.fim;
                });

                if (res) {
                  setPeriodosContributivos((prev) => res.data);

                  form.setFieldsValue({ periodosContributivos: res.data });

                  await services
                    .obterRegrasAplicaveis(
                      resp.data.vinculos[0].id,
                      currentDateRef
                    )
                    .then((response) => {
                      setRegrasAplicaveis((prev) => response.regrasAplicaveis);
                      setPermiteVerTempoContribuicao(
                        (prev) => response.permiteVerTempoContribuicao
                      );
                      setDescricaoPeriodosAdicionais(
                        (prev) => response.descricaoPeriodosAdicionais
                      );
                      setDataReferenciaModificada(
                        (prev) => response.dataReferenciaModificada
                      );
                      setSumula((prev) => response.sumula);
                      setIsExibe((prev) => true);
                      setSimulacaoBeneficioEspecial(
                        (prev) => response.simulacaoBeneficioEspecial
                      );
                      setSimulacaoBeneficioEspecial(
                        (prev) => response.simulacaoBeneficioEspecial
                      );
                      setLoadingPeriodosContributivos((prev) => false);
                      setLoadingRegrasAplicaveis((prev) => false);
                      setBeneficioEspecial(
                        (prev) => response.permiteAdesaoBeneficioEspecial
                      );
                      setResumoTempoRGPS(
                        (prev) => response.sumula.resumoContribuicaoGeral
                      );
                      setResumoTempoRPPS(
                        (prev) => response.sumula.resumoServicoPulico
                      );
                    })
                    .catch(() => {
                      setLoadingRegrasAplicaveis((prev) => false);
                      setLoadingPeriodosContributivos((prev) => false);
                      setLoadingRegrasAplicaveis((prev) => false);
                      setBeneficioEspecial((prev) => false);
                    });
                }
              })
              .catch(() => {
                setIsExibe((prev) => false);
                setLoading((prev) => false);
              });
          }
          setLoading((prev) => false);
        });
      }
    }
  }, []);

  React.useEffect(() => {
    if (refreshVinculo) {
      const date = convertObjectNewDateToStr();
      const currentDateRef = transformarDataStringComTraco(date);
      setLoadingRegrasAplicaveis((prev) => true);
      const formData = form.getFieldsValue();
      if (formData.referencia) {
        formData.referencia = converterDataParaAnoMesDia(formData.referencia);
      }

      setLoadingPeriodosContributivos((prev) => true);
      services
        .getAverbacoes(idVinculo)
        .then(async (res) => {
          res.data.map((periodo) => {
            periodo.fim = periodo.fim === "Atual" ? "Atual" : periodo.fim;
          });

          if (res) {
            setPeriodosContributivos((prev) => res.data);

            form.setFieldsValue({ periodosContributivos: res.data });

            await services
              .obterRegrasAplicaveis(idVinculo, currentDateRef)
              .then((response) => {
                setRegrasAplicaveis((prev) => response.regrasAplicaveis);
                setDataReferenciaModificada(
                  (prev) => response.dataReferenciaModificada
                );
                setPermiteVerTempoContribuicao(
                  (prev) => response.permiteVerTempoContribuicao
                );
                setDescricaoPeriodosAdicionais(
                  (prev) => response.descricaoPeriodosAdicionais
                );
                setSumula((prev) => response.sumula);
                setIsExibe((prev) => true);
                setSimulacaoBeneficioEspecial(
                  (prev) => response.simulacaoBeneficioEspecial
                );
                setSimulacaoBeneficioEspecial(
                  (prev) => response.simulacaoBeneficioEspecial
                );
                setLoadingPeriodosContributivos((prev) => false);
                setLoadingRegrasAplicaveis((prev) => false);
                setBeneficioEspecial(
                  (prev) => response.permiteAdesaoBeneficioEspecial
                );
                setResumoTempoRGPS(
                  (prev) => response.sumula.resumoContribuicaoGeral
                );
                setResumoTempoRPPS(
                  (prev) => response.sumula.resumoServicoPulico
                );
              })
              .catch(() => {
                setLoadingRegrasAplicaveis((prev) => false);
                setLoadingPeriodosContributivos((prev) => false);
                setLoadingRegrasAplicaveis((prev) => false);
                setBeneficioEspecial((prev) => false);
                setLoading((prev) => false);
              });
          }
        })
        .catch(() => {
          setIsExibe((prev) => false);
          setLoading((prev) => false);
        });
    }
  }, [refreshVinculo]);

  const addPeriodoContributivo = async (index, adicionar) => {
    const formData = form.getFieldsValue();

    form
      .validateFields()
      .then(async () => {
        if (adicionar) {
          return adicionar();
        }

        const periodosAdicionados = [];
        await formData.periodosContributivos.map((periodo, i) => {
          if (!periodo.razao) {
            periodo.index = i;
            periodo.inicio = converterDataParaAnoMesDia(periodo.inicio);
            periodo.fim = converterDataParaAnoMesDia(periodo.fim);
            periodo.servicoPublico = periodo.tipo === 1 ? true : false;
            periodosAdicionados.push(periodo);
          }
        });

        if (idVinculo) {
          await services
            .obterRegrasAplicaveis(idVinculo, referencia, periodosAdicionados)
            .then(async (response) => {
              setRegrasAplicaveis((prev) => response.regrasAplicaveis);
              setPermiteVerTempoContribuicao(
                (prev) => response.permiteVerTempoContribuicao
              );
              setDataReferenciaModificada(
                (prev) => response.dataReferenciaModificada
              );
              setDescricaoPeriodosAdicionais(
                (prev) => response.descricaoPeriodosAdicionais
              );
              setSumula((prev) => response.sumula);
              setSimulacaoBeneficioEspecial(
                (prev) => response.simulacaoBeneficioEspecial
              );
              setResumoTempoRGPS(
                (prev) => response.sumula.resumoContribuicaoGeral
              );
              setResumoTempoRPPS((prev) => response.sumula.resumoServicoPulico);
              if (index) {
                formData.periodosContributivos.map((periodo, i) => {
                  periodo.index = i;
                  periodo.inicio = transformarDataStringComBarra(
                    periodo.inicio
                  );
                  periodo.fim =
                    periodo.fim === "Atual"
                      ? "Atual"
                      : transformarDataStringComBarra(periodo.fim);
                });

                const newPeriodosContributivos = [...periodosContributivos];
                newPeriodosContributivos[index] = {
                  adicionado: true,
                  inicio: formData.periodosContributivos[index].inicio,
                  fim: formData.periodosContributivos[index].fim,
                  tipo: formData.periodosContributivos[index].tipo,
                  deducoes: formData.periodosContributivos[index].deducoes,
                };
                setPeriodosContributivos((prev) => newPeriodosContributivos);
              }
            })
            .catch((e) => {
              if (index) {
                formData.periodosContributivos.map((periodo, i) => {
                  periodo.index = i;
                  periodo.inicio = transformarDataStringComBarra(
                    periodo.inicio
                  );
                  periodo.fim =
                    periodo.fim === "Atual"
                      ? "Atual"
                      : transformarDataStringComBarra(periodo.fim);
                });

                const newPeriodosContributivos = [...periodosContributivos];
                newPeriodosContributivos[index] = {
                  adicionado: false,
                  inicio: formData.periodosContributivos[index].inicio,
                  fim: formData.periodosContributivos[index].fim,
                  tipo: formData.periodosContributivos[index].tipo,
                  deducoes: formData.periodosContributivos[index].deducoes,
                };
                setPeriodosContributivos((prev) => newPeriodosContributivos);
              }
            });
        }
      })
      .catch((e) => {
        if (index) {
          formData.periodosContributivos.map((periodo, i) => {
            periodo.index = i;
            periodo.inicio = transformarDataStringComBarra(periodo.inicio);
            periodo.fim =
              periodo.fim === "Atual"
                ? "Atual"
                : transformarDataStringComBarra(periodo.fim);
          });

          const newPeriodosContributivos = [...periodosContributivos];
          newPeriodosContributivos[index] = {
            adicionado: false,
            inicio: formData.periodosContributivos[index].inicio,
            fim: formData.periodosContributivos[index].fim,
            tipo: formData.periodosContributivos[index].tipo,
            deducoes: formData.periodosContributivos[index].deducoes,
          };
          setPeriodosContributivos((prev) => newPeriodosContributivos);
        }
      });
  };

  const imprimirRelatorioTempoContribuicao = async () => {
    setLoadingRelatorioTempoContribuicao((prev) => true);
    await services
      .visualizarRelatorioTempoContribuicao(idVinculo)
      .then(() => {
        setLoadingRelatorioTempoContribuicao((prev) => false);
      })
      .catch(() => setLoadingRelatorioTempoContribuicao((prev) => false));
  };

  const atualizarRegras = async (index, periodos) => {
    const formData = form.getFieldsValue();

    const periodosAdicionados = [];
    await periodos?.map((periodo, i) => {
      if (!periodo.razao) {
        periodo.index = i;
        periodo.inicio = converterDataParaAnoMesDia(periodo.inicio);
        periodo.fim = converterDataParaAnoMesDia(periodo.fim);
        periodo.servicoPublico = periodo.tipo === 1 ? true : false;
        periodosAdicionados.push(periodo);
      }
    });

    if (idVinculo) {
      await services
        .obterRegrasAplicaveis(idVinculo, referencia, periodosAdicionados)
        .then((response) => {
          setRegrasAplicaveis((prev) => response.regrasAplicaveis);
          setPermiteVerTempoContribuicao(
            (prev) => response.permiteVerTempoContribuicao
          );
          setDataReferenciaModificada(
            (prev) => response.dataReferenciaModificada
          );
          setDescricaoPeriodosAdicionais(
            (prev) => response.descricaoPeriodosAdicionais
          );
          setSumula((prev) => response.sumula);
          setSimulacaoBeneficioEspecial(
            (prev) => response.simulacaoBeneficioEspecial
          );
          setResumoTempoRGPS((prev) => response.sumula.resumoContribuicaoGeral);
          setResumoTempoRPPS((prev) => response.sumula.resumoServicoPulico);
          setPeriodosContributivos((prev) => periodos);
        })
        .catch((e) => {
          if (index) {
            formData.periodosContributivos.map((periodo, i) => {
              periodo.index = i;
              periodo.inicio = transformarDataStringComBarra(periodo.inicio);
              periodo.fim =
                periodo.fim === "Atual"
                  ? "Atual"
                  : transformarDataStringComBarra(periodo.fim);
            });

            const newPeriodosContributivos = [...periodosContributivos];
            newPeriodosContributivos[index] = {
              adicionado: false,
              inicio: formData.periodosContributivos[index].inicio,
              fim: formData.periodosContributivos[index].fim,
              tipo: formData.periodosContributivos[index].tipo,
              deducoes: formData.periodosContributivos[index].deducoes,
            };
            setPeriodosContributivos((prev) => newPeriodosContributivos);
          }
        });
    }
  };

  const removePeriodoContributivo = (remove, index) => {
    remove(index);
    periodosContributivos.splice(index, 1);
    atualizarRegras(index, periodosContributivos);
  };

  const returnTipoServico = (tipo, isFicto, strTipo) => {
    switch (tipo) {
      case 1:
        return (
          <p className={!isFicto ? "tempoFicto" : "servicoPublico"}>
            Serviço Público {!isFicto ? "- Tempo Ficto" : ""} {strTipo === 'FUNCIONAL' || strTipo === 'RPPS' ? " - Efetivo" : ""} 
          </p>
        );
      case 2:
        return (
          <p className={!isFicto ? "tempoFicto" : "servicoPrivado"}>
            Serviço Privado {!isFicto ? "- Tempo Ficto" : ""}
          </p>
        );

      case undefined:
        return "";
      default:
        break;
    }
  };

  const verifyExistPeriodAdd = () => {
    return periodosContributivos.some((period) => period.adicionado === true);
  };

  const imprimirSimulacaoFinal = () => {
    const formData = form.getFieldsValue();

    if (!formData.regra) {
      formData.regra = regrasAplicaveis[0].regra;
    }

    form.validateFields(["regra"]).then(() => {
      if (idVinculo) {
        setLoadingSimulacaoFinal((prev) => true);
        services
          .visualizarRelatorioFinal(idVinculo, referencia, formData.regra)
          .then((response) => {
            setLoadingSimulacaoFinal((prev) => false);
            imprimirRelatorio(response);
            setOpenModalFinalSimulator((prev) => false);
          })
          .catch((error) => {
            setLoadingSimulacaoFinal((prev) => false);
            if (error.response.status === 403) {
              history.push("/acesso-negado");
            }
          });
      }
    });
  };

  const imprimirSimulacaoCompleta = async () => {
    const formData = form.getFieldsValue();

    const periodosAdicionados = [];
    await formData.periodosContributivos?.map((periodo, i) => {
      if (!periodo.razao) {
        periodo.index = i;
        periodo.inicio = converterDataParaAnoMesDia(periodo.inicio);
        periodo.fim = converterDataParaAnoMesDia(periodo.fim);
        periodo.servicoPublico = periodo.tipo === 1 ? true : false;
        periodosAdicionados.push(periodo);
      }
    });

    if (idVinculo) {
      setLoadingSimulacaoCompleta((prev) => true);
      services
        .visualizarRelatorioCompleto(idVinculo, referencia, periodosAdicionados)
        .then((response) => {
          imprimirRelatorio(response);
          setLoadingSimulacaoCompleta((prev) => false);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            history.push("/acesso-negado");
          }
          setLoadingSimulacaoCompleta((prev) => false);
        });
    }
  };

  const imprimirSimulacaoMedia = async () => {
    const formData = form.getFieldsValue();
    formData.stopDate = transformarDataStringComTraco(formData.stopDate);

    if (idVinculo) {
      setLoadingCalculodaMedia((prev) => true);
      services
        .visualizarCalculoMedia(idVinculo, referencia, formData.stopDate)
        .then((response) => {
          imprimirRelatorio(response);

          setLoadingCalculodaMedia((prev) => false);
        })
        .catch((error) => {
          setLoadingCalculodaMedia((prev) => false);
          if (error.response.status === 403) {
            history.push("/acesso-negado");
          }
        });
    }
  };

  const irParaTelaBeneficio = () => {
    history.push({
      pathname: "/beneficio/especial",
      state: {
        cpf: dataFuncional.cpf,
        referencia: referencia,
        vinculo: idVinculo,
        simulacaoBeneficioEspecial: simulacaoBeneficioEspecial,
      },
    });
  };

  return (
    <>
      <Secured
        hasAnyRole={Permissions.SIMULACAO_APOSENTADORIA.SIMULAR_APOSENTADORIA}
        isShowErro403={false}
      >
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError={true}
          initialValues={{
            stopDate: "29/12/2019",
            regra: regrasAplicaveis
              ? regrasAplicaveis[0]?.regra
              : regras[0].value,
          }}
        >
          <Row gutter={[10, 10]}>
            <Secured
              hasAnyRole={
                Permissions.SIMULACAO_APOSENTADORIA.SIMULAR_APOSENTADORIA
              }
              isShowErro403={false}
            >
              {dataFuncional && (
                <Secured
                  hasAnyRole={
                    Permissions.SIMULACAO_APOSENTADORIA.CONSULTAR_RESUMO
                  }
                >
                  <>
                    <Col span={24}>
                      <Row gutter={[10, 10]}>
                        <ResumoContributivo
                          dataFuncional={dataFuncional}
                          setIdVinculo={setIdVinculo}
                          setRefreshVinculo={setRefreshVinculo}
                          dataReferenciaModificada={dataReferenciaModificada}
                          sumula={sumula}
                          loading={loading}
                          resumoTempoRGPS={resumoTempoRGPS}
                          resumoTempoRPPS={resumoTempoRPPS}
                        />
                      </Row>
                    </Col>
                      {beneficioEspecial && (
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            width: "100%",
                          }}
                          gutter={[10]}
                        >
                          <Button
                            type="primary"
                            icon={<BarChartOutlined />}
                            onClick={() => irParaTelaBeneficio()}
                          >
                            O servidor tem a opção do Beneficio Especial, clique
                            aqui para simular
                          </Button>
                        </Row>
                      )}
                  </>
                </Secured>
              )}

              <Secured
                hasAnyRole={
                  Permissions.SIMULACAO_APOSENTADORIA.CONSULTAR_PERIODOS
                }
              >
                {periodosContributivos?.length > 0 && (
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Card title="Períodos Contributivos">
                          <Form.List name={"periodosContributivos"}>
                            {(fields, { add, remove }, { errors }) => (
                              <>
                                <Row gutter={[10, 10]} className="grid">
                                  {fields.map((field, index) => (
                                    <>
                                      <Col
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={8}
                                        key={index}
                                      >
                                        <Card
                                          key={index}
                                          hoverable={true}
                                          style={{
                                            height: "100%",
                                            flex: 1,
                                            borderColor:
                                              periodosContributivos[index]
                                                ?.razao ||
                                              periodosContributivos[index]
                                                ?.adicionado
                                                ? "#665f1c"
                                                : "",
                                          }}
                                          loading={loadingPeriodosContributivos}
                                        >
                                          <h6>
                                            {periodosContributivos[index]
                                              ?.razao ||
                                            periodosContributivos[index]
                                              ?.adicionado ? (
                                              returnTipoServico(
                                                periodosContributivos[index]
                                                  ?.tipo,
                                                periodosContributivos[
                                                  field.name
                                                ]?.razao,
                                                periodosContributivos[index]
                                                  ?.strTipo
                                              )
                                            ) : (
                                              <p className="semTempo"></p>
                                            )}
                                          </h6>
                                          <h5>{`${
                                            periodosContributivos[index]?.razao
                                              ? periodosContributivos[index]
                                                  ?.razao
                                              : ""
                                          }`}</h5>
                                          <Row gutter={10}>
                                            {!periodosContributivos[index]
                                              ?.razao && (
                                              <>
                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={12}
                                                >
                                                  <p>
                                                    {
                                                      !periodosContributivos[
                                                        index
                                                      ]?.razao
                                                    }
                                                  </p>

                                                  <Form.Item
                                                    name={[index, "tipo"]}
                                                    label="Tipo de Averbação"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Selecione um tipo!",
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      disabled={
                                                        periodosContributivos[
                                                          index
                                                        ]?.adicionado
                                                          ? true
                                                          : false
                                                      }
                                                      placeholder="Selecione..."
                                                      options={tiposDeAverbacoes.map(
                                                        (value) => ({
                                                          label: value.nome,
                                                          value: value.value,
                                                        })
                                                      )}
                                                    />
                                                  </Form.Item>
                                                </Col>

                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={12}
                                                >
                                                  <p>
                                                    {
                                                      !periodosContributivos[
                                                        index
                                                      ]?.razao
                                                    }
                                                  </p>
                                                  <Form.Item
                                                    name={[index, "deducoes"]}
                                                    label="Deduções"
                                                  >
                                                    <PatternFormat
                                                      disabled={
                                                        periodosContributivos[
                                                          index
                                                        ]?.adicionado
                                                          ? true
                                                          : false
                                                      }
                                                      placeholder="0000"
                                                      format="####"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}
                                          </Row>
                                          <Row gutter={10}>
                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              lg={12}
                                            >
                                              <Form.Item
                                                name={[index, "inicio"]}
                                                label="Data do Início"
                                                dependencies={[index, "fim"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Campo obrigatório!",
                                                  },

                                                  {
                                                    validator: async (
                                                      info,
                                                      value
                                                    ) => {
                                                      if (
                                                        !periodosContributivos[
                                                          index
                                                        ]?.razao
                                                      ) {
                                                        return InputDate.Validator(
                                                          "inicio",
                                                          value,
                                                          "##/##/####"
                                                        );
                                                      }
                                                    },
                                                  },
                                                ]}
                                              >
                                                <InputDate
                                                  disabled={
                                                    periodosContributivos[index]
                                                      ?.adicionado ||
                                                    periodosContributivos[index]
                                                      ?.razao
                                                      ? true
                                                      : false
                                                  }
                                                  placeholder="00/00/0000"
                                                  format="##/##/####"
                                                />
                                              </Form.Item>
                                            </Col>

                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              lg={12}
                                            >
                                              <Form.Item
                                                name={[index, "fim"]}
                                                label="Data do Fim"
                                                dependencies={[index, "inicio"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Campo obrigatório!",
                                                  },

                                                  {
                                                    validator: async (
                                                      info,
                                                      value
                                                    ) => {
                                                      if (
                                                        !periodosContributivos[
                                                          index
                                                        ]?.razao
                                                      ) {
                                                        return InputDate.Validator(
                                                          "fim",
                                                          value,
                                                          "##/##/####"
                                                        );
                                                      }
                                                    },
                                                  },
                                                ]}
                                              >
                                                {periodosContributivos[index]
                                                  ?.razao ? (
                                                  <Input
                                                    disabled={
                                                      periodosContributivos[
                                                        index
                                                      ]?.adicionado ||
                                                      periodosContributivos[
                                                        index
                                                      ]?.razao
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                ) : (
                                                  <InputDate
                                                    disabled={
                                                      periodosContributivos[
                                                        index
                                                      ]?.adicionado ||
                                                      periodosContributivos[
                                                        index
                                                      ]?.razao
                                                        ? true
                                                        : false
                                                    }
                                                    placeholder="00/00/0000"
                                                    format="##/##/####"
                                                  />
                                                )}
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                          <Row gutter={10}>
                                            {!periodosContributivos[field.name]
                                              ?.razao && (
                                              <Col>
                                                <p>
                                                  <p
                                                    style={{
                                                      color: "#ff4d4f",
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Atenção!{" "}
                                                  </p>
                                                  Essas informações serão
                                                  utilizados apenas nesta
                                                  simulação, não sendo
                                                  adicionada ao seu cadastro na
                                                  Goiasprev. Para o uso desse
                                                  período no processo de
                                                  aposentadoria é necessário o
                                                  protocolo da Certidão de Tempo
                                                  de Contribuição na Goiasprev.
                                                </p>
                                              </Col>
                                            )}

                                            {!periodosContributivos[index]
                                              ?.razao && (
                                              <>
                                                <Row
                                                  gutter={10}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginTop: "32px",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Col>
                                                    <Form.Item name="adicionado">
                                                      <Button
                                                        type="primary"
                                                        icon={
                                                          periodosContributivos[
                                                            index
                                                          ]?.adicionado ? (
                                                            <CheckOutlined />
                                                          ) : (
                                                            <PlusOutlined />
                                                          )
                                                        }
                                                        disabled={
                                                          periodosContributivos[
                                                            index
                                                          ]?.adicionado
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          addPeriodoContributivo(
                                                            index
                                                          )
                                                        }
                                                        htmlType="submit"
                                                      >
                                                        {periodosContributivos[
                                                          index
                                                        ]?.adicionado
                                                          ? "Adicionado"
                                                          : "Adicionar"}
                                                      </Button>
                                                    </Form.Item>
                                                  </Col>

                                                  <Col>
                                                    <Button
                                                      type="primary"
                                                      danger
                                                      icon={<DeleteOutlined />}
                                                      onClick={() =>
                                                        removePeriodoContributivo(
                                                          remove,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      Excluir
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </>
                                            )}
                                          </Row>
                                        </Card>
                                      </Col>
                                    </>
                                  ))}
                                  <Secured
                                    hasAnyRole={
                                      Permissions.SIMULACAO_APOSENTADORIA
                                        .INSERIR_TEMPO_ADICIONAL
                                    }
                                  >
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                      <AddButtonCard
                                        handleAdd={() =>
                                          addPeriodoContributivo(null, add)
                                        }
                                        minHeight={
                                          verifyExistPeriodAdd()
                                            ? "575px"
                                            : "197px"
                                        }
                                        loading={loadingPeriodosContributivos}
                                      />
                                    </Col>
                                  </Secured>
                                </Row>
                                {permiteVerTempoContribuicao && dataFuncional && (
                                  <Secured
                                    hasAnyRole={
                                      Permissions.SIMULACAO_APOSENTADORIA
                                        .GERAR_PDF_PERIODOS_CONTRIBUICAO_FINAL
                                    }
                                  >
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "32px",
                                        width: "100%",
                                      }}
                                    >
                                      <Col>
                                        <Button
                                          type="primary"
                                          icon={<PrinterOutlined />}
                                          loading={
                                            loadingRelatorioTempoContribuicao
                                          }
                                          onClick={() =>
                                            imprimirRelatorioTempoContribuicao()
                                          }
                                        >
                                          Relatório de Tempo de Contribuição
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Secured>
                                )}
                              </>
                            )}
                          </Form.List>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Secured>

              {permiteVerTempoContribuicao && (
                <>
                  <Secured
                    hasAnyRole={
                      Permissions.SIMULACAO_APOSENTADORIA.REGRAS_APLICAVEIS
                    }
                  >
                    {regrasAplicaveis?.length > 0 && (
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <RegrasAplicaveis
                              regrasAplicaveis={regrasAplicaveis}
                              loading={loadingRegrasApliveis}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Secured>

                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                    }}
                    gutter={[10]}
                  >
                    <Secured
                      hasAnyRole={
                        Permissions.SIMULACAO_APOSENTADORIA
                          .GERAR_PDF_REGRAS_APLICAVEIS_FINAL
                      }
                    >
                      <Col>
                        <Button
                          type="primary"
                          icon={<PrinterOutlined />}
                          onClick={() => setOpenModalFinalSimulator(true)}
                        >
                          Simulação Final
                        </Button>
                      </Col>
                    </Secured>

                    <Secured
                      hasAnyRole={
                        Permissions.SIMULACAO_APOSENTADORIA
                          .GERAR_PDF_REGRAS_APLICAVEIS
                      }
                    >
                      <Col>
                        <Button
                          type="primary"
                          icon={<PrinterOutlined />}
                          onClick={() => imprimirSimulacaoCompleta()}
                          loading={loadingSimulacaoCompleta}
                        >
                          Simulação Completa
                        </Button>
                      </Col>
                    </Secured>

                    <Secured
                      hasAnyRole={
                        Permissions.SIMULACAO_APOSENTADORIA
                          .GERAR_PDF_REGRAS_APLICAVEIS_FINAL
                      }
                    >
                      <Col>
                        <Button
                          type="primary"
                          icon={<PrinterOutlined />}
                          onClick={() => imprimirSimulacaoMedia()}
                          loading={loadingCalculodaMedia}
                        >
                          Cálculo da Média
                        </Button>
                      </Col>
                    </Secured>
                  </Row>
                </>
              )}
            </Secured>
          </Row>

          <Modal
            open={openModalFinalSimulator}
            onCancel={() => setOpenModalFinalSimulator(false)}
            title={"Imprimir Simulação Final"}
            closable={true}
            footer={[
              <Button onClick={() => setOpenModalFinalSimulator(false)}>
                Cancelar
              </Button>,
              <Button
                type="primary"
                // loading={props.loading}
                icon={<PrinterOutlined />}
                onClick={() => imprimirSimulacaoFinal()}
                loading={loadingSimulacaoFinal}
              >
                Imprimir
              </Button>,
            ]}
          >
            <Form layout="vertical" form={form}>
              <Row gutter={10}>
                {regrasAplicaveis && (
                  <Col span={24}>
                    <Form.Item name={["regra"]} label="Regras">
                      <Select
                        placeholder="Selecione..."
                        defaultValue={regrasAplicaveis[0]?.regra}
                        options={regrasAplicaveis.map((value) => ({
                          label: value.regra,
                          value: value.regra,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Modal>
        </Form>

        {!permiteVerTempoContribuicao && isExibe && (
          <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
            <Col span={24}>
              <Card style={{ background: "#ff0000", color: "#fff" }}>
                <Row>
                  <Col span={24}>
                    <i className=" mr-2 fas fa-exclamation-triangle"></i>
                    <span>
                      Há inconsistência(s) nos seus dados por concomitância de
                      averbações. Por favor, entre em contato com seu órgão de
                      origem e dê entrada em um processo de atualização
                      cadastral junto à GOIASPREV.
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </Secured>
    </>
  );
};

export default SimulacaoAposentadoria;
